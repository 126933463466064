import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/blocktab.css";
import "../styles/tab.css";
import "../styles/icon.css";

const TestimonialsTabs = () => {
  return (
    <Tabs selectedTabClassName="activetab">

      <TabPanel className="tab-panel animate-fade">
        <h2 className="max-w-5xl mx-auto mt-12 text-2xl font-semibold text-white md:mt-0 md:text-center md:text-4xl">
          Powering AVIS Malaysia vehicle leasing operation nationwide
        </h2>
        <div className="max-w-2xl mx-auto mt-4 mb-8 text-xs leading-6 md:text-center md:text-lg text-bluegray md:leading-8">
          One of the largest car leasing providers in the country, DRB-HICOM EZ-Drive Sdn Bhd (AVIS Malaysia) relies on KATSANA Fleet Management System to <span className="text-primary stroke-primary">improve car leasing, service & maintenance operations.</span>
        </div>

        <div className="grid gap-6 md:gap-10 grid-cols-1 md:grid-cols-2 max-w-6xl mx-auto mt-12">

          {/* ROW 1 */}
          <div className="flex flex-col pr-0 md:pr-8">

            <div className="flex flex-col pt-2 md:pt-5">
              <StaticImage
                alt="Shahfarid Mansur"
                src="../images/avatar-avis.png"
                className="block rounded-full w-[85px]"
                objectFit="contain"
              />
              
              <div className="">
                <p className="mt-4 mb-0 text-sm leading-5 md:leading-6 md:text-sm text-bluegray">
                  Mohamad Shahfarid Mansur
                </p>
                <p className="ext-sm leading-5 md:leading-6 md:text-sm text-bluegray">
                  Project Manager, DRB-HICOM EZ-Drive Sdn Bhd/AVIS
                </p>
              </div>
            </div>

            <div className="mt-4 md:mt-8">
              <p className="text-white text-2xl font-semibold">
                "AVIS provides commercial vehicle leasing to various corporate clients with different operational challenges, and KATSANA is able to provide <span className="text-primary">specific solutions for specific needs</span> of each client.
                <br/>
              </p>
              <p className="text-white text-2xl font-semibold mt-6">
                Been using for more than 5 years and <span className="text-primary">KATSANA consistently improve the solutions over the years.</span>"
              </p>
            </div>

          </div>

          {/* ROW 2 */}
          <div className="w-full">
            
            <div className="grid gap-4 md:gap-4 grid-cols-1 md:grid-cols-2">
              
              <div className="pt-20">
                <StaticImage
                  alt="Avis"
                  src="../../static/images/updates/av1v.jpg"
                  objectFit="contain"
                  className="rounded-2xl"
                />
              </div>

              <div>
                <StaticImage
                  alt="Avis"
                  src="../../static/images/updates/av2v.jpg"
                  objectFit="contain"
                  className="rounded-2xl"
                />
              </div>
              
            </div>

          </div>
        
        </div>
      </TabPanel>
      
      <TabPanel className="tab-panel animate-fade">
        <h2 className="max-w-5xl mx-auto mt-12 text-2xl font-semibold text-white md:mt-0 md:text-center md:text-4xl">
          Supporting Aerodyne Group's critical fleet operations
        </h2>
        <div className="max-w-2xl mx-auto mt-4 mb-8 text-xs leading-6 md:text-center md:text-lg text-bluegray md:leading-8">
          The world’s largest, fastest growing Drone Service and Solution Provider integrates KATSANA real-time API into its operations platform to provide critical inspection services for MNC customers.
        </div>

        <div className="grid gap-6 md:gap-10 grid-cols-1 md:grid-cols-2 max-w-6xl mx-auto mt-12">

          {/* ROW 1 */}
          <div className="flex flex-col pr-0 md:pr-8">

            <div className="flex flex-col pt-2 md:pt-5">
              <StaticImage
                alt="Kamarul Muhamed"
                src="../../static/images/updates/kamarul-muhamed.jpg"
                className="block rounded-full w-[85px] h-[85px]"
                objectFit="cover"
              />
              
              <div className="">
                <p className="mt-4 mb-0 text-sm leading-5 md:leading-6 md:text-sm text-bluegray">
                  Dato' Kamarul Muhamed
                </p>
                <p className="ext-sm leading-5 md:leading-6 md:text-sm text-bluegray">
                  Group CEO, Aerodyne Group - World's #1 Drone Service Provider
                </p>
              </div>
            </div>

            <div className="mt-4 md:mt-8">
              <p className="text-white text-2xl font-semibold">
                "Aerodyne operates in over 35 countries. 
                <br/>
              </p>
              <p className="text-white text-2xl font-semibold mt-6">
                Our ground operation relies on KATSANA Fleet Management Solutions to <span className="text-primary">coordinate and ensure smooth delivery of services </span>to our Fortune 500 clients globally."
              </p>
            </div>

          </div>

          {/* ROW 2 */}
          <div className="w-full">
            
            <div className="grid gap-4 md:gap-4 grid-cols-2 md:grid-cols-2">
              
              <div className="pt-20">
                <StaticImage
                  alt="Aerodyne"
                  src="../../static/images/updates/ad1.jpg"
                  objectFit="contain"
                  className="rounded-2xl"
                />
              </div>

              <div>
                <StaticImage
                  alt="Aerodyne"
                  src="../../static/images/updates/ad2.jpg"
                  objectFit="contain"
                  className="rounded-2xl"
                />
              </div>
              
            </div>

          </div>
        
        </div>
      </TabPanel>

      <TabPanel className="tab-panel animate-fade">
        <h2 className="max-w-5xl mx-auto mt-12 text-2xl font-semibold text-white md:mt-0 md:text-center md:text-4xl">
          Helping Kudrat Group of Companies recover over 40 missing rental vehicles
        </h2>
        <div className="max-w-2xl mx-auto mt-4 mb-8 text-xs leading-6 md:text-center md:text-lg text-bluegray md:leading-8">
          Vehicle rental & leasing providers are exposed to many risks, especially in theft and dangerous driving that lead to asset losses. Trusting KATSANA since 2016, the company has recouped its investment within the same year.
        </div>

        <div className="grid gap-6 md:gap-10 grid-cols-1 md:grid-cols-2 max-w-6xl mx-auto mt-12">

          {/* ROW 1 */}
          <div className="flex flex-col pr-0 md:pr-8">

            <div className="flex flex-col pt-2 md:pt-5">
              <StaticImage
                alt="Cheong Mun Yuen"
                src="../images/avatar-kudrat.png"
                className="block rounded-full w-[85px]"
                objectFit="contain"
              />
              
              <div className="">
                <p className="mt-4 mb-0 text-sm leading-5 md:leading-6 md:text-sm text-bluegray">
                  Cheong Mun Yuen
                </p>
                <p className="ext-sm leading-5 md:leading-6 md:text-sm text-bluegray">
                  Fleet Manager, Kudrat Group of Companies
                </p>
              </div>
            </div>

            <div className="mt-4 md:mt-8">
              <p className="text-white text-2xl font-semibold">
                "KATSANA® has been an invaluable addition to our fleet of vehicles.
                <br/>
              </p>
              <p className="text-white text-2xl font-semibold mt-6">
                Apart from enabling us to identify risky drivers, we have been able to <span className="text-primary">recover over 40 missing vehicles</span> from hire-purchase debtors; all with <span className="text-primary">100% recovery rate of missing vehicles.</span>"
              </p>
            </div>

          </div>

          {/* ROW 2 */}
          <div className="w-full">
            
            <div className="grid gap-4 md:gap-4 grid-cols-2 md:grid-cols-2">
              
              <div className="pt-20">
                <StaticImage
                  alt="Gentari"
                  src="../../static/images/updates/kudrat.jpg"
                  objectFit="contain"
                  className="rounded-2xl"
                />
              </div>

              <div>
                <StaticImage
                  alt="Gentari"
                  src="../../static/images/updates/kudrat-2.jpg"
                  objectFit="contain"
                  className="rounded-2xl"
                />
              </div>
              
            </div>

          </div>
        
        </div>
      </TabPanel>

  
     <TabPanel className="tab-panel animate-fade">
        <h2 className="max-w-5xl mx-auto mt-6 text-2xl font-semibold text-white md:mt-0 md:text-center md:text-4xl">
         Improving Highway Safety for LITRAK
        </h2>
        <div className="max-w-2xl mx-auto mt-4 mb-8 text-xs leading-6 md:text-center md:text-lg text-bluegray md:leading-8">
          Lingkaran Trans Kota Holdings Berhad (LITRAK) implements <a href="/fleet-management-malaysia/"><span className="text-primary stroke-primary">KATSANA Fleet Management Solutions</span></a> to improve the safety of its highway patrol service operations.
        </div>

        <div className="grid gap-6 md:gap-10 grid-cols-1 md:grid-cols-2 max-w-6xl mx-auto mt-12">

          {/* ROW 1 */}
          <div className="flex flex-col pr-0 md:pr-8">

            <div className="flex flex-col pt-2 md:pt-5">
              <StaticImage
                alt="Zainuddin Noah"
                src="../images/avatar-litrak.png"
                className="block rounded-full w-[85px]"
                objectFit="contain"
              />
              
              <div className="">
                <p className="mt-4 mb-0 text-sm leading-5 md:leading-6 md:text-sm text-bluegray">
                  Zainudin Mohamed Noah
                </p>
                <p className="ext-sm leading-5 md:leading-6 md:text-sm text-bluegray">
                  Head, Traffic Safety Department
                </p>
              </div>
            </div>

            <div className="mt-4 md:mt-8">
              <p className="text-white text-2xl font-semibold">
                "I am happy with the service that KATSANA is providing for us at LITRAK.
                <br/>
              </p>
              <p className="text-white text-2xl font-semibold mt-6">
                We recorded <span className="text-primary">noticeable improvements in fleet operations & occupational safety</span> ever since we started using the KATSANA® Fleet Management system on our patrol motorcycles and tow trucks.
              </p>
            </div>

          </div>

          {/* ROW 2 */}
          <div className="w-full">
            
            <div className="grid gap-4 md:gap-4 grid-cols-2 md:grid-cols-2">
              
              <div className="pt-20">
                <StaticImage
                  alt="Gentari"
                  src="../../static/images/updates/t1.jpg"
                  objectFit="contain"
                  className="rounded-2xl"
                />
              </div>

              <div>
                <StaticImage
                  alt="Gentari"
                  src="../../static/images/updates/t2.jpg"
                  objectFit="contain"
                  className="rounded-2xl"
                />
              </div>
              
            </div>

          </div>
        
        </div>
      </TabPanel>
   

      <TabList className="testimonialtab mt-12 grid gap-4 grid-cols-4">
      

        <Tab>
          <div className="text-sm md:text-base text-bluegray bg-bluelight h-28 flex items-center justify-center">
            <StaticImage
              alt="AVIS Malaysia"
              src="../../static/images/logos/companies/updates/avis.png"
              className="w-full object-contain"
              width={90}
              objectFit="contain"
            />
          </div>
        </Tab>

        <Tab>
          <div className="text-sm md:text-base text-bluegray bg-bluelight h-28 flex items-center justify-center">
            <StaticImage
              alt="Aerodyne Group"
              src="../../static/images/logos/companies/updates/aerodyne.png"
              className="w-full object-contain"
              width={130}
              objectFit="contain"
            />
          </div>
        </Tab>

        <Tab>
          <div className="text-sm md:text-base text-bluegray bg-bluelight h-28 flex items-center justify-center">
            <StaticImage
              alt="Kudrat Malaysia"
              src="../../static/images/logos/companies/updates/kudrat.png"
              className="w-full object-contain"
              width={58}
              objectFit="contain"
            />
          </div>
        </Tab>

        <Tab>
          <div className="text-sm md:text-base text-bluegray bg-bluelight h-28 flex items-center justify-center">
            <StaticImage
              alt="Lingkaran Trans Kota"
              src="../../static/images/logos/companies/updates/litrak.png"
              className="w-full object-contain"
              width={90}
              objectFit="contain"
            />
          </div>
        </Tab>



      
      </TabList>
      
    </Tabs>
  );
};

export default TestimonialsTabs;
