import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import "../styles/accordion.css";

const TestimonialsAccordion = () => {
  return (
    <div className="block ring-1 rounded-2xl py-2 px-6 ring-white ring-opacity-20">

      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full py-4 items-center text-md font-medium text-left text-bluegray focus:outline-none border-b border-white border-opacity-20`}
            >
              <span>Review: AVIS Malaysia</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-white" : ""
                } w-5 h-5 text-bluegray`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="pt-6 pb-12 text-sm text-gray-500 border-white border-b border-opacity-20">
              
              <h2 className="max-w-5xl mx-auto text-2xl font-semibold text-white mt-0">
                Powering AVIS Malaysia vehicle leasing operation nationwide
              </h2>
              <div className="max-w-2xl mt-4 mb-4 text-md leading-6 text-bluegray">
                 One of the largest car leasing providers in the country, DRB-HICOM EZ-Drive Sdn Bhd (AVIS Malaysia) relies on KATSANA Fleet Management System to <span className="text-primary stroke-primary">improve car leasing, service & maintenance operations.</span>
              </div>

              <div className="grid gap-6 gap-10 grid-cols-1 max-w-6xl mx-auto mt-6">
                <div className="flex flex-col">

                  <div className="mt-0 bg-bluelight rounded-2xl px-5 py-5">
                    <p className="text-white md:text-lg xs:text-md font-normal italic">
                    "AVIS provides commercial vehicle leasing to various corporate clients with different operational challenges, and KATSANA is able to provide <span className="text-primary">specific solutions for specific needs</span> of each client.
                      <br/>
                    </p>
                    <p className="text-white md:text-lg xs:text-md font-normal italic">
                    Been using for more than 5 years and <span className="text-primary">KATSANA consistently improve the solutions over the years.</span>"
                     </p>              
                  </div>

                  <div className="flex items-center justify-between mt-6">
                    <div className="flex items-center">
                      <StaticImage
                        alt="Shahfarid Mansur"
                        src="../images/avatar-avis.png"
                        className="block rounded-full w-[45px] mr-4"
                        objectFit="contain"
                      />
                      <div className="text-sm">
                        <p className="mb-0 font-semibold md:leading-6 text-sm text-bluegray">
                          Mohamad Shahfarid Mansur
                        </p>
                        <p className="leading-6 text-xs sm:text-sm text-bluegray">
                          Project Manager, DRB-HICOM EZ-Drive Sdn Bhd/AVIS
                        </p>
                      </div>
                    </div>

                    <StaticImage
                      alt="katsana"
                      src="../../static/images/logos/companies/updates/avis.png"
                      className="object-contain"
                      width={85}
                      objectFit="contain"
                    />
                  </div>
                </div>

                <div className="w-full hidden">                  
                  <div className="grid gap-4 md:gap-4 grid-cols-2 md:grid-cols-2">
                    
                    <div className="pt-20">
                      <StaticImage
                        alt="AVIS Malaysia"
                        src="../../static/images/updates/av1v.jpg"
                        objectFit="contain"
                        className="rounded-2xl"
                      />
                    </div>

                    <div>
                      <StaticImage
                        alt="AVIS Malaysia"
                        src="../../static/images/updates/av2v.jpg"
                        objectFit="contain"
                        className="rounded-2xl"
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full py-4 items-center text-md font-medium text-left text-bluegray focus:outline-none border-b border-white border-opacity-20`}
            >
              <span>Review: Aerodyne Group</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-white" : ""
                } w-5 h-5 text-bluegray`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="pt-6 pb-12 text-sm text-gray-500 border-white border-b border-opacity-20">
              <h2 className="max-w-5xl mx-auto text-2xl font-semibold text-white mt-0">
                Supporting Aerodyne Group's critical fleet operations
              </h2>
              <div className="max-w-2xl mt-4 mb-4 text-md leading-6 text-bluegray">
                 The world’s largest, fastest growing Drone Service and Solution Provider integrates KATSANA real-time API into its operations platform to provide critical inspection services for MNC customers.
              </div>

              <div className="grid gap-6 gap-10 grid-cols-1 max-w-6xl mx-auto mt-0">
                <div className="flex flex-col">

                  <div className="mt-0 bg-bluelight rounded-2xl px-5 py-5">
                    <p className="text-white md:text-lg xs:text-md font-normal italic">
                      "Aerodyne operates in over 35 countries. 
                      <br/>
                    </p>
                     <p className="text-white md:text-lg xs:text-md font-normal italic">
                Our ground operation relies on KATSANA Fleet Management Solutions to <span className="text-primary">coordinate and ensure smooth delivery of services </span>to our Fortune 500 clients globally."
                   </p>
                  </div>

                  <div className="flex items-center justify-between mt-6">
                    <div className="flex items-center">
                      <StaticImage
                        alt="Kamarul Muhamed"
                        src="../../static/images/updates/kamarul-muhamed.jpg"
                        className="block rounded-full w-[45px] mr-4"
                        objectFit="contain"
                      />
                      <div className="text-sm">
                        <p className="mb-0 font-semibold md:leading-6 text-sm text-bluegray">
                          Dato' Kamarul Muhamed
                        </p>
                        <p className="leading-6 text-xs sm:text-sm text-bluegray">
                          Group CEO, Aerodyne Group - World's #1 Drone Service Provider
                        </p>
                      </div>
                    </div>

                    <StaticImage
                      alt="Aerodyne Group"
                      src="../../static/images/logos/companies/updates/aerodyne.png"
                      className="object-contain"
                      width={85}
                      objectFit="contain"
                    />
                  </div>
                </div>

                <div className="w-full hidden">                  
                  <div className="grid gap-4 md:gap-4 grid-cols-2 md:grid-cols-2">
                    
                    <div className="pt-20">
                      <StaticImage
                        alt="Aerodyne Group"
                        src="../../static/images/updates/ad1.jpg"
                        objectFit="contain"
                        className="rounded-2xl"
                      />
                    </div>

                    <div>
                      <StaticImage
                        alt="Aerodyne Group"
                        src="../../static/images/updates/ad2.jpg"
                        objectFit="contain"
                        className="rounded-2xl"
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full py-4 items-center text-md font-medium text-left text-bluegray focus:outline-none border-b border-white border-opacity-20`}
            >
              <span>Review: Kudrat Malaysia</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-white" : ""
                } w-5 h-5 text-bluegray`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="pt-6 pb-12 text-sm text-gray-500 border-white border-b border-opacity-20">
              <h2 className="max-w-5xl mx-auto text-2xl font-semibold text-white mt-0">
                Helping Kudrat Group of Companies recover over 40 missing rental vehicles
              </h2>
              <div className="max-w-2xl mt-4 mb-4 text-md leading-6 text-bluegray">
               Vehicle rental & leasing providers are exposed to many risks, especially in theft and dangerous driving that lead to asset losses. Trusting KATSANA since 2016, the company has recouped its investment within the same year.
              </div>

              <div className="grid gap-6 gap-10 grid-cols-1 max-w-6xl mx-auto mt-0">
                <div className="flex flex-col">

                  <div className="mt-0 bg-bluelight rounded-2xl px-5 py-5">
                    <p className="text-white md:text-lg xs:text-md font-normal italic">
                "KATSANA® has been an invaluable addition to our fleet of vehicles. 
                <br/>
                    </p>
                     <p className="text-white md:text-lg xs:text-md font-normal italic">
                Apart from enabling us to identify risky drivers, we have been able to <span className="text-primary">recover over 40 missing vehicles</span> from hire-purchase debtors; all with <span className="text-primary">100% recovery rate of missing vehicles.</span>"
                    </p>
                  </div>

                  <div className="flex items-center justify-between mt-6">
                    <div className="flex items-center">
                      <StaticImage
                        alt="Cheong Mun Yuen"
                        src="../images/avatar-kudrat.png"
                        className="block rounded-full w-[45px] mr-4"
                        objectFit="contain"
                      />
                      <div className="text-sm">
                        <p className="mb-0 font-semibold md:leading-6 text-sm text-bluegray">
                          Cheong Mun Yuen
                        </p>
                        <p className="leading-6 text-xs sm:text-sm text-bluegray">
                          Fleet Manager, Kudrat Group of Companies
                        </p>
                      </div>
                    </div>

                    <StaticImage
                      alt="katsana"
                      src="../../static/images/logos/companies/updates/kudrat.png"
                      className="object-contain"
                      width={65}
                      objectFit="contain"
                    />
                  </div>
                </div>

                <div className="w-full hidden">                  
                  <div className="grid gap-4 md:gap-4 grid-cols-2 md:grid-cols-2">
                    
                    <div className="pt-20">
                      <StaticImage
                        alt="Kudrat Malaysia"
                        src="../../static/images/updates/kudrat.jpg"
                        objectFit="contain"
                        className="rounded-2xl"
                      />
                    </div>

                    <div>
                      <StaticImage
                        alt="Kudrat Malaysia"
                        src="../../static/images/updates/kudrat-2.jpg"
                        objectFit="contain"
                        className="rounded-2xl"
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      <Disclosure as="div">
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full py-4 items-center text-md font-medium text-left text-bluegray focus:outline-none border-b-0`}
            >
              <span>Review: LITRAK Highway Operator</span>
              <ChevronRightIcon
                className={`${
                  open ? "transform rotate-90 text-white" : ""
                } w-5 h-5 text-bluegray`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="pt-6 pb-12 text-sm text-gray-500 border-white border-b-0">
              <h2 className="max-w-5xl mx-auto text-2xl font-semibold text-white mt-0">
                Improving Highway Safety for LITRAK Patrol Vehicles
              </h2>
              <div className="max-w-2xl mt-4 mb-4 text-md leading-6 text-bluegray">
               Lingkaran Trans Kota Holdings Berhad (LITRAK) implements <a href="/fleet-management-malaysia/"><span className="text-primary stroke-primary">KATSANA Fleet Management Solutions</span></a> to improve the safety of its highway patrol service operations.
              </div>

              <div className="grid gap-6 gap-10 grid-cols-1 max-w-6xl mx-auto mt-0">
                <div className="flex flex-col">

                  <div className="mt-0 bg-bluelight rounded-2xl px-5 py-5">
                    <p className="text-white md:text-lg xs:text-md font-normal italic">
                "I am very pleased with the service that KATSANA is providing for us at LITRAK.
                <br/>
                    </p>
                     <p className="text-white md:text-lg xs:text-md font-normal italic">
             We recorded <span className="text-primary">noticeable improvements in fleet operations & occupational safety</span> ever since we started using the KATSANA® Fleet Management system on our patrol motorcycles and tow trucks.
                    </p>
                  </div>

                  <div className="flex items-center justify-between mt-6">
                    <div className="flex items-center">
                      <StaticImage
                        alt="Zainuddin Noah"
                        src="../images/avatar-litrak.png"
                        className="block rounded-full w-[45px] mr-4"
                        objectFit="contain"
                      />
                      <div className="text-sm">
                        <p className="mb-0 font-semibold md:leading-6 text-sm text-bluegray">
                          Zainuddin Mohamed Noah
                        </p>
                        <p className="leading-6 text-xs sm:text-sm text-bluegray">
                          Head, Traffic Safety Department
                        </p>
                      </div>
                    </div>

                    <StaticImage
                      alt="katsana"
                      src="../../static/images/logos/companies/updates/litrak.png"
                      className="object-contain"
                      width={75}
                      objectFit="contain"
                    />
                  </div>
                </div>

                <div className="w-full hidden">                  
                  <div className="grid gap-4 md:gap-4 grid-cols-2 md:grid-cols-2">
                    
                    <div className="pt-20">
                      <StaticImage
                        alt="Litrak"
                        src="../../static/images/updates/t1.jpg"
                        objectFit="contain"
                        className="rounded-2xl"
                      />
                    </div>

                    <div>
                      <StaticImage
                        alt="Litrak"
                        src="../../static/images/updates/t2.jpg"
                        objectFit="contain"
                        className="rounded-2xl"
                      />
                    </div>
                    
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

export default TestimonialsAccordion;
