import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

function IVOSolutions() {

  return (
    <div className="px-8 py-4 md:p-8 lg:p-8">

      <h2 className="max-w-3xl mx-auto mt-0 md:mt-2 text-2xl font-semibold text-white  md:text-center md:text-4xl">
        Integrated Vehicle Operation Solutions
      </h2>

      <p className="max-w-2xl mx-auto mt-4 mb-7 md:mb-14 lg:mb-14 text-xs leading-5 md:text-center md:text-lg text-bluegray md:leading-8">
        Complete suite of solutions you need to manage fleet efficiently
        and cost-effective, all on a unified Fleet Management platform.
      </p>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-6 lg:gap-7 w-full z-10">

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="https://apps.katsana.com/get-quotation/" target="_blank">
            <div>
              <StaticImage
                alt="Live Vehicle Tracking, Speed & Driver Behavior for Fleet Operations"
                src="../../static/images/updates/img.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Live Vehicle Tracking, Speed & Driver Behavior for Fleet Operations</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="/fuel/">
            <div>
              <StaticImage
                alt="Fuel Monitoring, Fuel Report, Driver Refuel app & Fuel Cost Management"
                src="../../static/images/updates/img-1.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Fuel Monitoring, Fuel Report, Driver Refuel app & Fuel Cost Management</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="/mdvr-fleet-management/">
            <div>
              <StaticImage
                alt="Fleet Dashcam, ADAS, Onboard Cameras & Live Streaming (MDVR)"
                src="../../static/images/updates/img-2.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Fleet Dashcam, ADAS, Onboard Cameras & Live Streaming (MDVR)</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="https://apps.katsana.com/get-quotation/" target="_blank">
            <div>
              <StaticImage
                alt="Driver ID, Driver Logbook, Driving Compliance & Vehicle Access Control"
                src="../../static/images/updates/img-3.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Driver ID, Driver Logbook, Driving Compliance & Vehicle Access Control</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="https://apps.katsana.com/get-quotation/" target="_blank">
            <div>
              <StaticImage
                alt="Vehicle Inspection app, Service & Maintenance Shceduling"
                src="../../static/images/updates/img-4.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Vehicle Inspection app, Service & Maintenance Scheduling</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div className="group ring-4 ring-transparent hover:ring-primary hover:ring-4 flex flex-col items-center rounded-xl relative shadow-2xl overflow-hidden duration-700 relative">
          <a href="https://apps.katsana.com/get-quotation/" target="_blank">
            <div>
              <StaticImage
                alt="Driver Pay, Mileage Claims & Reimbursement System"
                src="../../static/images/updates/img-5.jpg"
                className="w-full object-fit"
              />
            </div>
            <div className="absolute z-10 bottom-0 h-full w-full bg-gradient-to-b from-bluelight/0 via-bluelight/70 to-bluelight">
              <div className="p-4 md:px-4 md:pt-4 md:pb-5 lg:px-6 lg:pt-5 lg:pb-6 font-bold text-sm md:text-lg lg:text-xl text-white/90 leading-4 md:leading-6 lg:leading-normal absolute w-full bottom-0">
                <h3 className="m-0">Driver Pay, Mileage Claims & Reimbursement System</h3>
                <div className="flex items-end text-white/80 mt-1 sm:mt-3 group-hover:text-primary transition duration-700">
                  <span className="text-sm font-normal">Learn more</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-4 ml-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </div>
              </div>
            </div>
          </a>
        </div>

      </div>

    </div>

    
  );
};

export default IVOSolutions;
